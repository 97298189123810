export const FirebaseConfig = {
	"projectId": "app-taxi-cloud",
	"appId": "1:725829721352:web:b6eac4989d3caf3daa20bd",
	"databaseURL": "https://app-taxi-cloud-default-rtdb.europe-west1.firebasedatabase.app",
	"storageBucket": "app-taxi-cloud.appspot.com",
	"locationId": "europe-west",
	"apiKey": "AIzaSyClD2PSfNQbJDFBXbaxzW9T4BBKIRFVuO0",
	"authDomain": "app-taxi-cloud.firebaseapp.com",
	"messagingSenderId": "725829721352",
	"measurementId": "G-F0H08FC92N"
};